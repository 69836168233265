<template>
  <KPage
    title="Esqueci minha senha"
    subtitle=" Um e-mail será enviado para o endereço informado com instruções
                para a recuperação."
  >
    <k-form ref="form">
      <v-row class="my-0">
        <!-- Campo de e-mail -->
        <v-col cols="12" xs="12">
          <v-text-field
            dense
            outlined
            label="E-mail"
            hint="Digite o e-mail que deseja ter sua senha recuperada"
            persistent-hint
            :disabled="step > 1"
            v-model="data.email"
            :error-messages="errors.email"
            :rules="[$validation.isRequired]"
          />
        </v-col>

        <!-- Animação de expansão dos dados -->
        <v-expand-transition>
          <!-- Código recebido por e-mail -->
          <v-col cols="12" xs="12" v-show="step >= 2">
            Digite o código recebido por e-mail
            <v-otp-input
              dense
              type="number"
              length="6"
              outlined
              label="Código recebido por e-mail"
              hint="Digite o código de 6 dígitos recebido por e-mail"
              persistent-hint
              @finish="validateToken"
              :disabled="step > 2"
              v-model="data.token"
              :error-messages="errors.token"
              :rules="[$validation.isRequired]"
            />
          </v-col>
          <!-- </v-row> -->
        </v-expand-transition>
      </v-row>

      <v-expand-transition>
        <v-row class="block" v-show="step >= 3">
          <!-- Nova senha -->
          <v-col cols="12" xs="12">
            <v-password
              dense
              outlined
              label="Nova Senha"
              v-model="data.password"
              :error-messages="errors.password"
              :rules="[$validation.isRequired]"
            />
          </v-col>

          <!-- Repita a senha -->
          <v-col cols="12" xs="12">
            <v-password
              dense
              outlined
              label="Repita a Senha"
              v-model="data.password_confirmation"
              :error-messages="errors.password_confirmation"
              :rules="[$validation.isRequired]"
            />
          </v-col>
        </v-row>
      </v-expand-transition>
    </k-form>
    <v-row class="ma-0">
      <v-btn
        rounded
        block
        v-if="step === 1"
        text
        color="secondary"
        @click="sendReminder"
        :loading="loading"
        >Solicitar Código por E-mail</v-btn
      >

      <!-- Validar código de acesso -->
      <v-btn
        rounded
        block
        v-if="step === 2"
        text
        color="secondary"
        @click="validateToken"
        :loading="loading"
        >Validar Código</v-btn
      >

      <!-- Finalizar a alteração da senha -->
      <v-btn
        rounded
        block
        v-if="step === 3"
        text
        color="secondary"
        @click="changePassword"
        :loading="loading"
      >
        Alterar Senha
      </v-btn>

      <!-- Botões de voltar -->
      <v-row class="my-2">
        <!-- Se estiver no step=1, volte para login -->
        <v-col
          v-if="step === 1"
          cols="12"
          class="text-center pa-0 pb-2 caption"
        >
          <router-link to="/login">Voltar</router-link>
        </v-col>

        <!-- Se estiver no step maior que 1, volte para o step anterior -->
        <v-col v-if="step > 1" cols="12" class="text-center pa-0 pb-2 caption">
          <a href="#" @click="step--">Voltar</a>
        </v-col>
      </v-row>
    </v-row>
  </KPage>
</template>

<script>
import VPassword from "@/components/VPassword";
import KForm from "@/components/KForm";
import KPage from "@/components/KPage/auth";
import "./style.css";

export default {
  components: { VPassword, KForm, KPage },
  data() {
    return {
      data: {
        email: null,
        token: null,
        password: null,
        password_confirmation: null
      },

      // Passo da recuperação:
      // 1) Ainda vai digitar o e-mail
      // 2) Já digitou o e-mail e agora vai digitar o código
      // 3) Já validou o código e agora pode digitar a senha
      step: 1,
      //
      errors: {},
      // loading
      loading: false
    };
  },

  watch: {
    step(value) {
      this.goToStep(value);
    }
  },

  computed: {},
  // Métodos
  methods: {
    /**
     * Realiza login por meio de email ou cpf
     */
    async sendReminder() {
      try {
        this.errors = {};
        this.loading = true;
        const result = await this.$store.dispatch(
          "password_recovery/sendReminder",
          this.data
        );
        this.loading = false;
        this.step = 2;
        this.$message.success(result.message, 5000);
      } catch (error) {
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    /**
     * Realiza login por meio de email ou cpf
     */
    async validateToken() {
      try {
        this.errors = {};
        this.loading = true;
        const result = await this.$store.dispatch(
          "password_recovery/validateToken",
          this.data
        );
        this.loading = false;
        this.step = 3;
        this.$message.success(result.message, 5000);
      } catch (error) {
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    /**
     * Realiza login por meio de email ou cpf
     */
    async changePassword() {
      try {
        this.errors = {};
        this.loading = true;
        const result = await this.$store.dispatch(
          "password_recovery/changePassword",
          this.data
        );
        this.loading = false;

        // console.log(result);
        this.$message.success(result.message, 5000);

        this.$router.push("/login");
      } catch (error) {
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    goToStep(step) {
      if (step < 2) {
        this.data.token = null;
      }
      if (step < 3) {
        this.data.password = null;
        this.data.password_confirmation = null;
      }
    },

    clear() {
      this.step = 1;
    }
  }
};
</script>
